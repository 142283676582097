/* ==========================================================================
   #SIDEBAR
   ========================================================================== */

.c-sidebar {
	position: relative;
	width: 100%;
	height: 100%;
	border-right: $sidebar-border-width solid $sidebar-border-color;
	background-color: $sidebar-background-color;
}


/**
 * 1. Height Calc: fullheight - (sidebar brand's height + sidebar footer's height)
 * 2. Customize sidebar scrollbar
 */

.c-sidebar__body {
	height: calc(100vh - 140px); /* [1] */
	overflow-y: auto;


	&::-webkit-scrollbar { /* [2] */
		width: $scrollbar-width;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb { /* [2] */
		border-radius: $scrollbar-thumb-border-radius;
		background-color: $scrollbar-thumb-background-color;
	}
}

/**
 * 1. Make sure it equals the height of navbar for better alignment.
 */

.c-sidebar__brand {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	height: $sidebar-brand-height; /* [1] */
	padding: $sidebar-brand-padding;
	color: $sidebar-brand-color;
	font-size: $sidebar-brand-font-size;

	a {
		color: inherit;
		font-size: 0;
		font-weight: $font-weight-semibold;
	}
}


/**
 * Nested sidebar lists
 *
 * 1. Add some spacing in nested lists.
 */

.c-sidebar__list {
	margin-bottom: $sidebar-list-margin-bottom;

	.c-sidebar__list { /* [1] */
		margin-left: $sidebar-list-margin-left;

		.c-sidebar__link.is-active {
			border: 0;
			color: $sidebar-link-active-color;
		}
	}
}

.c-sidebar__item {
	position: relative;
}

.c-sidebar__link {
	display: flex;
	align-items: center;
	margin: $sidebar-link-margin;
	padding: $sidebar-link-padding;
	border-left: $sidebar-link-border-width solid transparent;
	color: $sidebar-link-color;
	font-size: $sidebar-link-font-size;

	&.is-active {
		border-left-color: $sidebar-link-active-border-color;
		color: $sidebar-link-active-color;
	}
}

.c-sidebar__icon {
	margin-right: $sidebar-icon-margin-right;
	font-size: $sidebar-icon-font-size;

	.c-sidebar__link.is-active & {
		color: $sidebar-icon-active-color;
	}
}

.c-sidebar__title {
	display: block;
	margin: 0;
	padding: $sidebar-title-padding;
	color: $color-ink-light;
	font-size: $sidebar-font-size;
	font-weight: $sidebar-font-weight;
	text-transform: uppercase;
}

.c-sidebar__footer {
	display: flex;
	position: absolute;
	bottom: 0;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: $sidebar-footer-height;
	padding: $sidebar-footer-padding;
	border-top: $sidebar-footer-border-width solid $sidebar-footer-border-color;
	background-color: $sidebar-footer-background-color;
	color: $sidebar-footer-color;

	&:hover {
		color: $sidebar-footer-hover-color;
	}
}

.c-sidebar__footer-icon {
	font-size: $sidebar-footer-icon-font-size;
}

/**
 * Sub Menu
 *
 * Submenu that is collapsed and will be visible after toggling the link.
 */

.c-sidebar__item.has-submenu > .c-sidebar__link {
	position: relative;
	margin: 0;
}

.c-sidebar__item.has-submenu > .c-sidebar__link::after { /* [1] */
	display: inline-block;
	position: absolute;
	top: 3px;
	right: 30px;
	color: $color-ink;
	font-family: $base-icon-font;
	font-size: 18px;
	content: "\e842"; // icon-chevron-down
}

.c-sidebar__item.has-submenu .c-sidebar__list {
	/**
	 * Bootstrap classes used to show and hide sidebar submenu.
	 * This behaviour depends on collapse.js plugin.
	 */
	&.collapse {
		display: none;
	}

	&.show {
		display: block;
	}

	&.collapsing {
		position: relative;
		height: 0;
		transition: height 0.3s;
		overflow: hidden;
	}
}


/**
 * Sidebar Toggle
 *
 * It's not placed in the sidebar, instead it's usually placed in the navbar and
 * used for showing/hiding sidebar in small screens.
 */

.c-sidebar-toggle {
	display: none;
	margin-right: $sidebar-toggle-margin-right;
	border: 0;
	background: transparent;
	font-size: $sidebar-toggle-font-size;

	&:hover {
		color: $sidebar-toggle-hover-color;
	}

	@include respond-to ($breakpoint-desktop) {
		display: block;
	}
}
