/* ==========================================================================
   #BADGES
   ========================================================================== */

.c-badge {
	display: inline-block;
	padding: $badge-padding;
	border: $badge-border-width solid $badge-border-color;
	border-radius: $badge-border-radius;
	background-color: transparent;
	color: $badge-color;
	font-size: $badge-font-size;
}


/* Size variants
   ========================================================================== */

.c-badge--small {
	padding: $badge-small-padding;
}

.c-badge--large {
	padding: $badge-large-padding;
}


/* Style variants
   ========================================================================== */

.c-badge--info {
	border-color: $color-info;
	color: $color-info;

	&:hover {
		background-color: $color-info-light;
		color: $color-info;
	}
}

.c-badge--success {
	border-color: $color-success;
	color: $color-success;

	&:hover {
		background-color: $color-success-light;
		color: $color-success;
	}
}

.c-badge--warning {
	border-color: $color-warning;
	color: $color-warning;

	&:hover {
		background-color: $color-warning-light;
		color: $color-warning;
	}
}

.c-badge--danger {
	border-color: $color-danger;
	color: $color-danger;

	&:hover {
		background-color: $color-danger-light;
		color: $color-danger;
	}
}

.c-badge--primary {
	border-color: $color-primary;
	color: $color-ink;

	&:hover {
		background-color: $color-primary-light;
		color: $color-ink;
	}
}

.c-badge--fancy {
	border-color: $color-fancy;
	color: $color-fancy;

	&:hover {
		background-color: $color-fancy-light;
		color: $color-fancy;
	}
}
