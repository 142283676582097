///* ========================================================================
//   #RANGE
//   ======================================================================== */

$range-thumb-width: 15px !default;
$range-thumb-height: 15px !default;

$range-thumb-gradient: $gradient-info !default;

$range-track-height: 4px !default;
$range-track-border-radius: 1px !default;
$range-track-background-color: $color-sky-dark !default;