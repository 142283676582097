///* ========================================================================
//   #PROGRESS
//   ======================================================================== */

// progress
$progress-height: 20px !default;
$progress-margin: 0 0 $spacer-small 0 !default;
$progress-border-radius: 20px !default;
$progress-background-color: $color-sky !default;

// size variations
$progress-xsmall-height: 2px !default;
$progress-small-height:  4px !default;
$progress-medium-height: 8px !default;

// color variations
$progress-bar-primary-gradient: $gradient-primary !default;
$progress-bar-success-gradient: $gradient-success !default;
$progress-bar-info-gradient:    $gradient-info !default;
$progress-bar-danger-gradient:  $gradient-danger !default;
$progress-bar-warning-gradient: $gradient-warning !default;
