///* ========================================================================
//   #CARDS
//   ======================================================================== */

// card
$card-margin-bottom: $spacer !default;
$card-padding: $spacer !default;
$card-border-radius: $base-border-radius !default;
$card-background-color: $base-background-color !default;
$card-box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.09) !default;


// card title
$card-title-margin-bottom: $spacer-xsmall !default;
