/* ==========================================================================
   #NOTES
   ========================================================================== */

.c-note {
	display: flex;
	padding: $spacer-small;
	border-radius: 4px;
	background-color: $color-sky-light;
	text-align: left;
}

.c-note__icon {
	margin-right: 10px;
}
