///* ========================================================================
//   #BUTTONS
//   ======================================================================== */

// button
$btn-padding: rem(10px) rem(20px) !default;
$btn-transition: all 0.15s ease-in-out !default;
$btn-border-radius: $base-border-radius !default;
$btn-background-color: $brand-color !default;
$btn-color: $color-white !default;
$btn-font-size: $text-font-size !default;
$btn-font-weight: $font-weight-normal !default;
$btn-line-height: $base-line-height / $base-font-size !default;
$btn-box-shadow: 0 5px 10px 0 rgba(29, 29, 31, .09) !default;

// hover & focus & active
$btn-hover-color: $color-white !default;
$btn-hover-box-shadow: 0 5px 20px 0 rgba(29, 29, 31, .09) !default;
$btn-focus-box-shadow: 0 0 0 3.5px rgba($brand-color, .3) !default;
$btn-hover-opacity: 0.9 !default;
$btn-active-transform: scale(0.92) !default;

// disabled
$btn-disabled-background-color: $color-ink-light !default;
$btn-disabled-opacity: .5;

// outline
$btn-outline-border-width: $base-border-width !default;
$btn-outline-color: $brand-color !default;

// sizes
$btn-small-font-size: $text-small-font-size !default;
$btn-small-padding: rem(4px) rem(18px) !default;

$btn-large-font-size: $text-large-font-size !default;
$btn-large-padding: rem(12px) rem(30px) !default;
