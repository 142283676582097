///* ========================================================================
//   #DROPDOWN
//   ======================================================================== */

// dropdown menu
$dropdown-menu-width: rem(150px) !default;
$dropdown-menu-large-width: rem(350px) !default;
$dropdown-menu-background-color: $base-background-color !default;

$dropdown-menu-border-width: $base-border-width !default;
$dropdown-menu-border-color: $base-border-color !default;
$dropdown-menu-border-radius: $base-border-radius !default;
$dropdown-menu-box-shadow: 0 10px 40px 0 rgba(29, 29, 31, 0.2) !default;

// dropdown item
$dropdown-item-padding: rem(8px) $spacer-small !default;
$dropdown-item-large-padding: $spacer-xsmall $spacer-small !default;
$dropdown-item-font-size: $text-font-size !default;
$dropdown-item-font-weight: $font-weight-normal !default;
$dropdown-item-color: $color-ink !default;
$dropdown-item-border-color: $color-sky !default;
$dropdown-item-border-width: $base-border-width !default;

$dropdown-item-hover-color: $brand-color !default;
$dropdown-item-hover-background-color: $color-sky-light !default;
