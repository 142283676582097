/* ==========================================================================
   #SELECT
   ========================================================================== */

.c-select {
	position: relative;

	&::after {
		position: absolute;
		top: 4px;
		right: 10px;
		color: $color-ink;
		font-family: $base-icon-font;
		font-size: 20px;
		content: "\e842"; // icon-chevron-down
	}

	&.is-disabled::after {
		opacity: .5;
	}
}

.c-select__input {
	display: block;
	width: 100%;
	margin: 0;
	padding: $select-padding;
	transition: all .3s;
	border: $select-border-width solid $select-border-color;
	border-radius: $select-border-radius;
	background-color: $select-background-color;
	color: $select-focus-color;
	font-size: $select-font-size;
	font-weight: $select-font-weight;
	resize: none;
	appearance: none;

	&:focus {
		border-color: $color-info;
		outline: 0;
	}

	.c-select.is-disabled &,
	&:disabled {
		border-color: $select-disabled-border-color;
		background-color: $select-disabled-background-color;
		color: $select-disabled-color;
	}
}
