/* ==========================================================================
   #CLOSE
   ========================================================================== */

/**
 * A small button for closing components, used with closable components 
 * like alerts, modals. 
 */

.c-close {
	padding: 0;
	border: 0;
	background-color: transparent;
	color: $close-color;
	font-size: $close-font-size;
	line-height: 1;
	opacity: $close-opacity;
	appearance: none;

	&:hover,
	&:focus {
		opacity: 1;
	}

	&:focus {
		outline: 0;
	}
}