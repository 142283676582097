///* ========================================================================
//   #ALERTS
//   ======================================================================== */

// alert
$alert-padding: $spacer !default;
$alert-border-width: $base-border-width !default;
$alert-border-radius: $base-border-radius !default;
$alert-background-color: $base-background-color !default;
$alert-box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.09) !default;

// alert icon
$alert-icon-width: rem(30px) !default;
$alert-icon-height: rem(30px) !default;
$alert-icon-color: $color-white !default;

// alert content 
$alert-content-padding-left: $spacer !default;

// alert title
$alert-title-margin-bottom: $spacer-small !default;
$alert-title-font-size: $h5-font-size !default;
