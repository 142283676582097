
/*!
 * Neat - v1.0
 * Copyright 2018 ZawiaStudio
 * @zawiastudio | zawiastudio.com 
 */

//
// Table of content:
// 
// Tools........... Neat's scss mixins & functions
// Settings........ Neat's scss variables
// Base............ Resets, bare elements styling
// Vendors......... Exteneral assests 
// Objects......... Layout-specific 
// Components...... UI-Kit, admin dashboard components
// Utilities....... Helper classes e.g. display utilities


//
// TOOLS
//
@import "tools/tools.linear-gradient";
@import "tools/tools.rem";
@import "tools/tools.respond-to";


//
// SETTINGS 
// 

// Global Settings
@import "settings/settings.global";

// UI-Kit Settings
@import "settings/settings.alert";
@import "settings/settings.avatar";
@import "settings/settings.badge";
@import "settings/settings.buttons";
@import "settings/settings.card";
@import "settings/settings.choice";
@import "settings/settings.divider";
@import "settings/settings.dropdown";
@import "settings/settings.field";
@import "settings/settings.icon";
@import "settings/settings.inputs";
@import "settings/settings.modal";
@import "settings/settings.navbar";
@import "settings/settings.pagination";
@import "settings/settings.progress";
@import "settings/settings.select";
@import "settings/settings.sidebar";
@import "settings/settings.switch";
@import "settings/settings.tables";
@import "settings/settings.tabs";
@import "settings/settings.toolbar";
@import "settings/settings.tooltips";
@import "settings/settings.close";
@import "settings/settings.range";


//
// BASE
//
@import "base/base.global";
@import "base/base.typography";


//
// VENDORS
//
@import "vendor/bootstrap-grid";
@import "vendor/fullcalendar";
@import "vendor/fullcalendar-custom"; // override fullcalendar default styles
@import "vendor/feather";
@import "vendor/dragula";
@import "vendor/chartist";
@import "vendor/chartist-plugin-tooltip";
@import "vendor/chartist-plugin-tooltip-custom"; // override chartist tooltip default styles


//
// OBJECTS
//
@import "objects/objects.page";
@import "objects/objects.media";
@import "objects/objects.line";


//
// COMPONENTS
//

// UI-Kit Components
@import "components/components.alert";
@import "components/components.avatar";
@import "components/components.badge";
@import "components/components.breadcrumb";
@import "components/components.buttons";
@import "components/components.card";
@import "components/components.choice";
@import "components/components.divider";
@import "components/components.dropdown";
@import "components/components.field";
@import "components/components.footer";
@import "components/components.icon";
@import "components/components.inputs";
@import "components/components.modal";
@import "components/components.navbar";
@import "components/components.note";
@import "components/components.notification";
@import "components/components.pagination";
@import "components/components.pipeline";
@import "components/components.progress";
@import "components/components.select";
@import "components/components.sidebar";
@import "components/components.switch";
@import "components/components.tables";
@import "components/components.tabs";
@import "components/components.text";
@import "components/components.toolbar";
@import "components/components.tooltips";
@import "components/components.close";
@import "components/components.range";

// Dashboard specifc-components
@import "components/components.state-card";
@import "components/components.feed";
@import "components/components.chart";
@import "components/components.plan";
@import "components/components.invoice";
@import "components/components.404";


// UTILITIES
@import "utilities/utilities.flex";
@import "utilities/utilities.spacing";
@import "utilities/utilities.typography";
@import "utilities/utilities.colors";
@import "utilities/utilities.display";
@import "utilities/utilities.borders";
@import "utilities/utilities.responsive";
@import "utilities/utilities.opacity";
@import "utilities/utilities.sizes";

