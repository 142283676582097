/* ==========================================================================
   #CHART
   ========================================================================== */

.c-chart {
	position: relative;
}

.c-chart__body {
	margin-bottom: $spacer-small;
}

.c-chart__legends {}

.c-chart__legend {
	display: block;
	margin-bottom: $spacer-xsmall;
	color: $text-color;
	font-size: $text-font-size;
	text-align: left;
}

.c-chart__legend-icon {
	display: inline-block;
	width: 15px;
	height: 8px;
	margin-right: 5px;
	border-radius: 4px;
	background-color: $color-primary;
}
