///* ========================================================================
//   #MODALS
//   ======================================================================== */


// Modal sizes (different sizes of the modal based on its width)
$modal-width: (
	xsmall: 350px,
	small:  450px,
	medium: 550px,
	large:  650px,
	xlarge: 750px,
	huge: 950px
);


$modal-dialog-margin: $spacer-large auto !default;
$modal-body-padding: $spacer !default;
$modal-body-background-color: $color-white !default;
$modal-maximized-height: 450px !default;
$modal-backdrop-background-color: rgba($color-ink-dark, .9) !default;
