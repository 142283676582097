/* ==========================================================================
   #TYPOGRAPHY
   ========================================================================== */

/**
 * Helpful component for managing different variations of text.
 */

.c-text--subtitle {
	color: $color-ink-light;
	font-size: $text-small-font-size;
	font-weight: $font-weight-bold;
	letter-spacing: 0.9px;
	text-transform: uppercase;
}


