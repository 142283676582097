///* ========================================================================
//   #ICONS
//   ======================================================================== */

// icon
$icon-width: 40px !default;
$icon-height: 40px !default;
$icon-background-color: $color-sky-light !default;

// different sizes
$icon-xsmall-width: 20px !default;
$icon-xsmall-height: 20px !default;

$icon-small-width: 30px !default;
$icon-small-height: 30px !default;

$icon-large-width: 50px !default;
$icon-large-height: 50px !default;

