///* ========================================================================
//   #CHOICE
//   ======================================================================== */


// Choice (shared settings between checkboxes and radios)
$choice-margin: 0 0 $spacer-small 0 !default;
$choice-color: $text-color !default;

$choice-font-size: $text-font-size !default;
$choice-font-weight: $font-weight-normal !default;

$choice-disabled-color: rgba($color-ink-dark, .5) !default;
$choice-disabled-border-color: $color-sky-dark !default;
$choice-disabled-background-color: $color-sky !default;

$choice-input-margin: 0 rem(8px) 0 0 !default;
$choice-input-border-width: $base-border-width !default;
$choice-input-border-color: $color-ink-light !default;
$choice-input-background-color: $color-white !default;
$choice-input-color: $color-white !default;

// Checkbox
$choice-checkbox-border-radius: 4px !default;
$choice-checkbox-border-color: $brand-color !default;
$choice-checkbox-background-color: $brand-color !default;

// Radio button
$choice-radio-border-width: 4px !default;
$choice-radio-border-color: $brand-color !default;
$choice-radio-background-color: $color-white !default;
$choice-radio-shadow-width: 1px !default;
$choice-radio-shadow-color: $brand-color !default;
