/* ==========================================================================
   #PLAN
   ========================================================================== */

/**
 * Pricing Plans
 */

.c-plan {
	margin-bottom: $spacer;
	padding: $spacer;
	transition: transform .3s;
	border-radius: $base-border-radius;
	background-color: $base-background-color;
	box-shadow: $card-box-shadow;

	&:hover {
		transform: scale(1.03);
	}
}

.c-plan__icon {
	margin-bottom: $spacer;
}

.c-plan__title {
	font-size: $h2-font-size;
}

.c-plan__price {
	margin-bottom: $spacer-small;
	font-size: $h3-font-size;
}

.c-plan__duration {
	color: $text-color;
	font-size: $text-font-size;
}

.c-plan__list {
	margin-bottom: $spacer;
}

.c-plan__list-item {
	margin-bottom: 5px;
	color: $text-color;
}


