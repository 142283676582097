///* ========================================================================
//   #TABS
//   ======================================================================== */

// tabs
$tabs-border-radius: $base-border-radius !default;
$tabs-background-color: $base-background-color !default;
$tabs-box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.09) !default;

// tabs list
$tabs-list-padding: 0 $spacer !default;
$tabs-list-background-color: $color-white !default;
$tabs-list-border-color: $color-sky !default;
$tabs-list-border-width: $base-border-width !default;
$tabs-list-border-radius: 4px !default;

// tab item
$tabs-item-margin: 0 $spacer 0 0 !default;

// item link
$tabs-link-padding: rem(20px) 0 rem(21px) 0 !default; // to override tab header's border-bottom
$tabs-link-font-size: $h6-font-size !default;
$tabs-link-color: $color-ink !default;

$tabs-link-hover-color: $color-ink-dark !default;

$tabs-link-active-color: $color-ink-dark !default;
$tabs-link-active-border-width: 2px !default;
$tabs-link-active-border-color: $brand-color !default;

// tab pane/content
$tabs-pane-background-color: $color-white !default;
$tabs-pane-margin: 0 !default;
$tabs-pane-padding: $spacer !default;
$tabs-pane-border-radius: 4px !default;
$tabs-pane-border-color: $color-sky !default;
$tabs-pane-border-width: $base-border-width !default;
$tabs-pane-color: $text-color !default;
