///* ========================================================================
//   #SWITCHES
//   ======================================================================== */

$switch-width: 48px !default;
$switch-height: 26px !default;
$switch-border-radius: 83px !default;

$switch-background-color: $color-ink-light !default;
$switch-active-background-color: $brand-color !default;

$switch-disabled-border-color: $color-sky-dark !default;
$switch-disabled-background-color: $color-sky !default;

// Switch toggle (the small circle the moves in the switch bar)
$switch-toggle-background-color: $color-white !default;
$switch-toggle-width: 20px !default;
$switch-toggle-height: 20px !default;

// Switch label
$switch-label-margin: 0 0 0 $spacer-small !default;
$switch-label-color: $text-color !default;
$switch-label-font-size: $text-font-size !default;
