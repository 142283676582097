/* ==========================================================================
   #PAGE OBJECT
   ========================================================================== */

/**
 * Use `o-page` to strucutre your page layout into sidebar, main content.
 */

.o-page {
	position: relative;
	height: 100%;

	@include respond-to ($breakpoint-desktop) {
		&.is-sidebar-open {
			overflow: hidden;

			.o-page__content {
				height: 100vh;
				transform: translate3d(250px, 0, 0);
				overflow: hidden;
			}
		}
	}
}


/**
 * Used with `o-page__card` for better alignment.
 */

.o-page--center {
	padding-top: $spacer-xlarge;

	@include respond-to($breakpoint-mobile) {
		padding-top: $spacer;
	}
}


/**
 * 1. Control the width of sidebar.
 * 2. Sidebar always on the top.
 */

.o-page__sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	width: 250px; /* [1] */
	height: 100%;
	max-height: 100%;
	//z-index: $z-index-100; /* [2] */

	/**
	 * Hide sidebar once viewport is lower than $breakpoint-desktop (992px)
	 *
	 * 1. Increase the width of sidebar on small screens.
	 */
	@include respond-to($breakpoint-desktop) {

	}
}


/**
 * Page content is pushed to the right to provide space for the sidebar.
 *
 * 1. Must equal the width of the `o-sidebar`.
 * 2. Adjust bootstrap `container-fluid` for proper spacing.
 * 3. Apply a background to the maint content to match with <body> background-color.
 */

.o-page__content {
	position: absolute;
	right: 0;
	left: 250px;
	min-height: 100%;
	transition: transform 0.3s;
	background-color: $body-background-color; /* [3] */

	@include respond-to($breakpoint-desktop) {
		left: 0;
	}
}


/** 
 * Page Card
 *
 * A centered page card is used when you want the card to be centered and 
 * provide a little space at the top & bottom e.g. login, 404 pages.
 */

.o-page__card {
	width: 400px;
	margin: 0 auto $spacer-large;

	@include respond-to($breakpoint-mobile) {
		width: 90%;
	}
}
