///* ========================================================================
//   #PAGINATION
//   ======================================================================== */


$pagination-link-width: 40px !default;
$pagination-link-height: 40px !default;
$pagination-link-margin-right: 5px !default;
$pagination-link-font-size: 18px !default;
$pagination-box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.09) !default;

$pagination-link-hover-color: $color-ink-dark !default;
$pagination-link-active-color: $brand-color !default;
