/* ==========================================================================
   #MEDIA OBJECT
   ========================================================================== */

/**
 * 1. Spacing between the image and the text should be declared through
 *    the component, usually using a `spacing` utility class. 
 */

.o-media {
	display: flex;
	align-items: flex-start;
}

.o-media__img {} /* [1] */

.o-media__body {
	flex: 1;
}

