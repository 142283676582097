///* ========================================================================
//   #TOOLBAR
//   ======================================================================== */

// toolbar
$toolbar-margin-bottom: $spacer-large !default;
$toolbar-padding: 0 $spacer !default;
$toolbar-background-color: $color-white !default;
$toolbar-box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.09) !default;

// toolbar nav link
$toolbar-nav-link-margin: 0 $spacer-small !default;
$toolbar-nav-link-padding: 20px 4px !default;
$toolbar-nav-link-color: $text-color !default;
$toolbar-nav-link-font-weight: $font-weight-semibold !default;

$toolbar-nav-link-active-border-width: 3px !default;
$toolbar-nav-link-active-border-color: $brand-color !default;
$toolbar-nav-link-active-color: $color-ink-dark !default;
