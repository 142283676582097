/* ==========================================================================
   #404 Page
   ========================================================================== */

/**
 * 404 Page
 *
 * Not really a reusable component but these styles are for 404 page.
 */

.c-404 {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.c-404__content {
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 350px;
	transform: translate(-50%, -50%);
	text-align: center;

	@include respond-to ($breakpoint-tablet) {
		width: 80%;
	}
}

.c-404__title {
	font-size: 96px;
	font-weight: 700;
}

.c-404__des {
	margin-bottom: $spacer;
	font-size: $text-large-font-size;
}

/**
 * Simple shapes
 */

.c-404__shape1 {
	position: absolute;
	top: 20px;
	right: -50px;
	width: 300px;
	height: 300px;
	border-radius: 100%;
	@include linear-gradient(to bottom, $gradient-danger);

	@include respond-to ($breakpoint-tablet) {
		right: -70px;
		width: 150px;
		height: 150px;
	}
}

.c-404__shape2 {
	position: absolute;
	bottom: 50px;
	left: -100px;
	width: 300px;
	height: 300px;
	border-radius: 100%;
	@include linear-gradient(to bottom, $gradient-info);

	@include respond-to ($breakpoint-tablet) {
		bottom: 20px;
		width: 150px;
		height: 150px;
	}
}
