/* ==========================================================================
   #STATE CARD
   ========================================================================== */

.c-state-card {
	position: relative;
	margin-bottom: $spacer;
	padding: $spacer;
	border-radius: $base-border-radius;
	@include linear-gradient(to bottom, $gradient-secondary);
}

.c-state-card__title {
	margin-bottom: $spacer-xsmall;
	color: $color-white;
	font-size: $text-font-size;
	text-transform: uppercase;
}

.c-state-card__number {
	display: block;
	margin-bottom: $spacer-small;
	color: $color-white;
	font-size: $h1-font-size;
}

.c-state-card__status {
	display: flex;
	align-items: center;
	color: $color-white;

	i {
		margin-right: $spacer-xsmall;
		font-size: 20px;
	}
}

.c-state-card__actions {
	position: absolute;
	top: $spacer-small;
	right: $spacer-small;
	color: $color-white;
	font-size: $h4-font-size;
}

/* Style variants
   ========================================================================== */

.c-state-card--info {
	@include linear-gradient(to bottom, $gradient-info);
}

.c-state-card--success {
	@include linear-gradient(to bottom, $gradient-success);
}

.c-state-card--fancy {
	@include linear-gradient(to bottom, $gradient-fancy);
}


















