/* ==========================================================================
   #DIVIDER
   ========================================================================== */

.c-divider {
	display: block;
	width: 100%;
	height: $divider-height;
	background-color: $divider-background-color;
}
