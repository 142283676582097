/* ==========================================================================
   #CARD
   ========================================================================== */

.c-card {
	margin-bottom: $card-margin-bottom;
	padding: $card-padding;
	border-radius: $card-border-radius;
	background-color: $card-background-color;
	box-shadow: $card-box-shadow;

	&.is-animated {
		transition: transform 0.3s;

		&:hover {
			transform: scale(1.03);
		}
	}
}

/**
 * Quicly align the content of card to the center.
 */

.c-card--center {
	text-align: center;
}

.c-card__title {
	margin-bottom: $card-title-margin-bottom;
}
