///* ========================================================================
//   #FIELD
//   ======================================================================== */


// Field
$field-margin: 0 0 rem(20px) 0 !default;

// Field label
$field-label-font-weight: $font-weight-normal !default;
$field-label-font-size: $text-font-size !default;
$field-label-color: $text-color !default;
$field-label-margin: 0 0 rem(5px) 0 !default;

// Field icon
$field-icon-color: $color-ink !default;
$field-icon-font-size: rem(18px) !default;

// Field message
$field-message-margin: 0 rem(5px) 0 0 !default;
$field-message-padding: 0 !default;

$field-message-color: rgba($text-color, 0.5) !default;
$field-message-font-size: $text-small-font-size !default;


