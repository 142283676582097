/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
	display: flex;
	align-items: center;
	margin-top: $spacer-large;
	padding: $spacer 0;
	border-top: 1px solid $color-sky-dark;
}

.c-footer__divider {
	margin: 0 $spacer-small;
	color: $color-ink-light;
}

.c-footer__link {
	margin-right: $spacer-small;
	color: $color-ink;
	font-size: $text-font-size;
}
