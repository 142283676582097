///* ========================================================================
//   #SIDEBAR
//   ======================================================================== */

// sidebar 
$sidebar-border-width: $base-border-width !default;
$sidebar-border-color: $color-sky-dark !default;
$sidebar-background-color: $color-sky-light !default;

// sidebar brand
$sidebar-brand-height: 70px !default;
$sidebar-brand-padding: rem(5px) $spacer-small rem(5px) $spacer !default;
$sidebar-brand-color: $color-ink-dark !default;
$sidebar-brand-font-size: $h4-font-size !default;

// sidebar list
$sidebar-list-margin-bottom: $spacer !default;
$sidebar-list-margin-left: $spacer !default;

// sidebar link
$sidebar-link-margin: $spacer-xsmall 0 !default;
$sidebar-link-padding: rem(5px) $spacer-xsmall rem(5px) $spacer !default;
$sidebar-link-border-width: 3px !default;
$sidebar-link-color: $color-ink !default;
$sidebar-link-font-size: $text-font-size !default;

$sidebar-link-active-border-color: $color-ink-dark !default;
$sidebar-link-active-color: $color-ink-dark !default;

// sidebar icon
$sidebar-icon-margin-right: $spacer-small !default;
$sidebar-icon-font-size: 22px !default;
$sidebar-icon-active-color: $brand-color !default;

// sidebar title
$sidebar-title-padding: rem(8px) $spacer-small rem(8px) $spacer !default;
$sidebar-title-color: $color-ink-light !default;
$sidebar-font-size: $text-small-font-size !default;
$sidebar-font-weight: $font-weight-bold !default;

// sidebar footer
$sidebar-footer-height: 70px !default;
$sidebar-footer-padding: rem(20px) $spacer !default;
$sidebar-footer-border-width: $base-border-width !default;
$sidebar-footer-border-color: $color-sky-dark !default;
$sidebar-footer-background-color: $color-sky-light !default;
$sidebar-footer-color: $color-ink !default;
$sidebar-footer-hover-color: $color-ink-dark !default;

// sidebar footer icon
$sidebar-footer-icon-font-size: 20px !default;

// sidebar toggle
$sidebar-toggle-margin-right: $spacer-small !default;
$sidebar-toggle-font-size: 28px !default;
$sidebar-toggle-hover-color: $color-ink-dark !default;

// sidebar scrollbar
$scrollbar-width: 6px !default;
$scrollbar-background-color: $color-sky-dark !default;

$scrollbar-thumb-border-radius: 6px !default;
$scrollbar-thumb-background-color: $color-ink-light !default;
